<template>
    <title-bar :title="$t('navigations.srTypes')"/>
    <resource-listing :resource="$ServiceRequestTypes" base-url="sr-types" add-button="messages.srTypeCreate"
                      delete-message="messages.srTypeDeleted" :emails="false"/>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceListing from '../../Settings/components/ResourceListing.vue'

export default {
    components: {
        TitleBar,
        ResourceListing
    },
}
</script>
